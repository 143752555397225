import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M1080 2868 c0 -5 47 -91 105 -190 l106 -180 -47 -82 c-26 -44 -54
-91 -62 -104 l-15 -23 114 3 113 3 54 90 c29 50 55 95 58 102 6 12 -199 372
-219 385 -17 12 -207 8 -207 -4z"/>
          <path d="M1450 2696 c0 -2 15 -28 33 -58 17 -29 43 -74 56 -99 l24 -45 -30
-49 c-78 -131 -183 -314 -183 -319 0 -3 49 -6 108 -6 l108 0 99 172 c55 94
104 179 109 189 6 13 -6 43 -50 118 l-58 101 -108 0 c-59 0 -108 -2 -108 -4z"/>
          <path d="M1952 2638 c-6 -29 -22 -104 -36 -165 l-24 -113 99 0 c91 0 99 2 99
19 0 11 3 26 6 35 5 13 -2 16 -43 16 -27 0 -52 2 -55 5 -2 3 -3 18 0 35 4 29
6 30 58 30 47 0 54 3 54 19 0 11 3 26 6 35 5 13 -3 16 -46 16 -43 0 -51 3 -46
16 3 9 6 20 6 25 0 5 24 9 54 9 47 0 54 3 60 23 12 46 11 47 -88 47 l-93 0
-11 -52z"/>
          <path d="M2203 2668 c3 -13 13 -48 22 -79 l17 -57 -39 -53 c-21 -30 -49 -69
-62 -86 l-22 -33 48 0 c47 0 49 1 83 55 19 30 38 55 42 55 4 0 12 -25 19 -55
l12 -55 48 0 48 0 -24 68 c-12 37 -26 74 -29 82 -4 10 15 46 54 98 l60 82 -48
0 c-45 0 -48 -2 -80 -51 -18 -28 -36 -48 -40 -44 -4 3 -12 25 -17 48 -10 40
-12 42 -54 45 -41 3 -43 2 -38 -20z"/>
          <path d="M2525 2668 c-27 -125 -65 -300 -65 -303 0 -3 20 -5 44 -5 l45 0 6 54
7 54 51 6 c63 9 117 57 117 104 0 78 -44 112 -144 112 -50 0 -56 -2 -61 -22z
m119 -64 c9 -23 -20 -64 -45 -64 -16 0 -19 4 -14 23 4 12 9 30 11 40 7 22 40
22 48 1z"/>
          <path d="M2816 2669 c-24 -104 -66 -301 -66 -304 0 -3 20 -5 44 -5 l44 0 7 48
c12 92 29 94 61 5 18 -53 18 -53 61 -53 24 0 43 2 43 5 0 2 -12 33 -26 68
l-26 63 31 31 c39 39 42 89 8 129 -21 24 -32 27 -100 32 -74 4 -76 4 -81 -19z
m118 -65 c9 -22 -11 -54 -34 -54 -20 0 -26 23 -14 54 8 21 40 21 48 0z"/>
          <path d="M3115 2668 c-27 -125 -65 -300 -65 -303 0 -3 42 -5 94 -5 99 0 106 3
106 51 0 16 -7 19 -51 19 -48 0 -51 1 -45 23 3 12 6 28 6 35 0 8 17 12 49 12
53 0 55 1 65 43 6 27 6 27 -44 27 -50 0 -55 4 -44 34 5 12 20 16 60 16 36 0
54 4 54 13 0 6 3 22 6 35 6 22 5 22 -90 22 -91 0 -96 -1 -101 -22z"/>
          <path d="M3404 2681 c-33 -14 -54 -51 -54 -92 0 -31 7 -44 40 -76 52 -48 52
-79 0 -77 -19 1 -45 5 -57 9 -21 7 -23 4 -23 -28 0 -32 4 -37 35 -47 46 -15
109 -4 143 25 22 19 27 32 27 70 0 42 -4 50 -43 83 -23 20 -42 42 -42 49 0 12
34 35 41 29 2 -2 16 -6 30 -9 23 -6 28 -2 39 23 7 17 10 34 6 37 -11 11 -119
14 -142 4z"/>
          <path d="M3648 2673 c-61 -38 -60 -109 3 -167 43 -41 46 -48 24 -66 -14 -12
-48 -10 -88 6 -16 6 -18 2 -15 -32 3 -32 7 -39 33 -46 45 -13 110 0 139 27 20
18 26 34 26 66 0 35 -6 47 -40 81 -58 58 -49 102 16 78 36 -14 38 -13 51 21
11 28 10 31 -13 40 -39 14 -106 10 -136 -8z"/>
          <path d="M1902 2193 c-2 -52 -1 -53 26 -52 22 1 23 2 5 6 -18 4 -23 12 -26 51
l-3 47 -2 -52z"/>
          <path d="M2013 2190 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z" />
          <path d="M2082 2188 l1 -53 6 43 6 44 25 -41 c14 -22 28 -41 33 -41 4 0 6 24
5 53 l-1 52 -6 -43 -6 -44 -25 41 c-14 22 -28 41 -32 41 -5 0 -7 -24 -6 -52z"/>
          <path d="M2224 2231 c5 -8 56 -3 56 5 0 2 -14 4 -31 4 -16 0 -28 -4 -25 -9z" />
          <path d="M2413 2224 c-4 -10 4 -24 23 -39 22 -18 25 -25 14 -28 -8 -3 -22 -8
-30 -10 -10 -4 -8 -6 6 -6 33 -1 49 23 29 43 -9 9 -21 16 -26 16 -19 0 -8 29
14 33 l22 4 -23 2 c-12 0 -25 -6 -29 -15z"/>
          <path d="M2522 2210 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
          <path d="M2590 2200 c-4 -34 -10 -46 -24 -48 -10 -2 -23 2 -28 10 -8 10 -9 10
-5 -2 5 -20 41 -24 56 -6 7 8 11 32 9 53 l-4 38 -4 -45z"/>
          <path d="M2660 2190 c0 -49 1 -50 30 -50 34 0 49 22 31 44 -8 9 -8 16 -2 20
19 12 -1 36 -30 36 -28 0 -29 -2 -29 -50z m55 25 c0 -5 -10 -11 -22 -13 -16
-2 -23 2 -23 13 0 11 7 15 23 13 12 -2 22 -7 22 -13z m0 -45 c0 -8 -10 -16
-22 -18 -18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z"/>
          <path d="M2793 2224 c-3 -8 -1 -20 5 -26 8 -8 9 -5 5 10 -4 16 1 21 19 25 l23
4 -23 2 c-12 0 -25 -6 -29 -15z"/>
          <path d="M2918 2229 c-21 -12 -24 -59 -6 -77 7 -7 23 -12 38 -11 l25 1 -24 7
c-13 3 -29 14 -35 24 -14 22 2 49 35 58 20 6 21 7 4 8 -11 0 -28 -4 -37 -10z"/>
          <path d="M3031 2188 c1 -29 4 -43 6 -30 5 27 18 28 35 3 13 -18 14 -15 13 27
0 44 -2 47 -27 50 -28 3 -28 3 -27 -50z m49 22 c0 -13 -7 -20 -20 -20 -13 0
-20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z"/>
          <path d="M3153 2190 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z" />
          <path d="M3231 2188 c1 -29 4 -43 6 -30 3 13 12 22 23 22 23 0 38 39 19 51 -8
5 -22 9 -31 9 -15 0 -18 -9 -17 -52z m44 22 c0 -8 -8 -16 -17 -18 -13 -2 -18
3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z"/>
          <path d="M3352 2233 c12 -3 18 -18 21 -51 l3 -47 2 47 c2 35 6 48 20 51 9 3
-1 5 -23 5 -22 0 -32 -2 -23 -5z"/>
          <path d="M3463 2190 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z" />
          <path d="M3540 2219 c-26 -47 26 -101 65 -69 18 15 20 61 3 78 -19 19 -56 14
-68 -9z m66 -14 c8 -33 -1 -55 -24 -55 -31 0 -47 26 -34 55 15 33 50 33 58 0z"/>
          <path d="M3683 2213 c0 -15 2 -22 5 -15 3 10 11 7 30 -10 l26 -23 -22 35 c-27
44 -40 48 -39 13z"/>
          <path d="M3748 2194 c-2 -25 0 -48 4 -50 5 -3 8 18 8 45 0 64 -7 66 -12 5z" />
          <path d="M2220 2199 c0 -13 14 -16 40 -9 24 6 24 7 -7 11 -18 3 -33 2 -33 -2z" />
          <path d="M2827 2171 c3 -14 -1 -21 -16 -24 -19 -4 -19 -5 1 -3 16 1 24 8 26
24 2 12 -1 22 -6 22 -6 0 -8 -9 -5 -19z"/>
          <path d="M3681 2164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M2224 2149 c-3 -5 9 -9 25 -9 17 0 31 2 31 4 0 8 -51 13 -56 5z" />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
